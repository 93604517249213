import React from "react"
import "./board.css"

export default function CGPBoardComponent({ children, className }) {
  return className ? (
    <div className={`board ${className}`}>{children}</div>
  ) : (
    <div className="board">{children}</div>
  )
}
