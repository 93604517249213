import React, { useState } from "react"

export default function CGPBoxComponent({
  children,
  onEnter,
  onLeave,
  onClick,
}) {
  const [mouseOver, setmouseOver] = useState(false)

  const [forgroundContent, backgroundContent] =
    children instanceof Array ? children : [children]

  const onHover = isFocus => {
    if (onEnter) {
      onEnter(isFocus)
    }

    if (backgroundContent) {
      setmouseOver(true)
    }
  }

  const onBlur = () => {
    onHover(false)
    if (onLeave) {
      onLeave()
    }

    if (backgroundContent) {
      setmouseOver(false)
    }
  }

  return (
    <>
      <div
        className={`box${(backgroundContent && " with-effect") || ""}`}
        aria-hidden="true"
        onClick={onClick}
        onMouseOver={() => onHover(true)}
        onFocus={() => onHover(true)}
        onMouseLeave={() => onBlur(false)}
        onBlur={() => onBlur(false)}
      >
        <div
          className={
            "row flex-center content " +
            (!mouseOver ? "animatingOn" : "animatingOff")
          }
        >
          {forgroundContent}
        </div>
        <div
          className={
            "row flex-center content " +
            (mouseOver ? "animatingOn" : "animatingOff")
          }
        >
          {backgroundContent}
        </div>
      </div>
    </>
  )
}
