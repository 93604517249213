import { MailOutlined, PhoneOutlined } from "@ant-design/icons"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { isMobile } from "react-device-detect"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import CGPBoardComponent from "../components/board/board.component"
import CGPBoxComponent from "../components/board/box.component"
import Layout from "../components/containers/layout"
import Seo from "../components/containers/seo"
import "../styles/contacts.css"

const Accueil = () => {
  return (
    <>
      <Seo title="Contact" />
      <Layout>
        <CGPBoardComponent className="contact-board">
          <CGPBoxComponent
            onClick={() => {
              if (isMobile) {
                window.location.href = "tel:0617338495"
              } else if (navigator?.clipboard?.writeText) {
                navigator.clipboard.writeText("+33 6 17 33 84 95")
                toast("Numéro de téléphone copié !")
              }

              window.gtag("event", "click", {
                event_category: "page-content",
                event_label: "call",
              })
            }}
          >
            <StaticImage src="../images/contact-tel.png" alt="phone number" />
            <div className="row flex-center">
              <p className="flex-center">
                <PhoneOutlined className="contact-icon" /> Appeler
                <br />
                +33 6 17 33 84 95
              </p>
            </div>
          </CGPBoxComponent>

          <CGPBoxComponent
            onClick={() => {
              if (isMobile) {
                window.location.href = "mailto:sebastien@aci-conseils.fr"
              } else if (navigator?.clipboard?.writeText) {
                navigator.clipboard.writeText("sebastien@aci-conseils.fr")
                toast("e-mail copié !")
              }

              window.gtag("event", "click", {
                event_category: "page-content",
                event_label: "mail",
              })
            }}
          >
            <StaticImage src="../images/contact-mail.png" alt="email" />
            <div className="row flex-center">
              <p className="flex-center">
                <MailOutlined className="contact-icon" /> Envoyer un mail
                <br />
                sebastien@aci-conseils.fr
              </p>
            </div>
          </CGPBoxComponent>
        </CGPBoardComponent>

        <ToastContainer />
      </Layout>
    </>
  )
}

export default Accueil
